.App {
    text-align: center;
    max-width: 500px;
    margin: 15px auto;
}

Image {
    padding: 15px;
}

Form {
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px;
}
